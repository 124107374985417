import React from "react";
import {
  SEO,
  PageLayout,
  HeroElement,
  FadeReveal,
  MarkdownContent,
  Image,
  Call,
} from "@bluefin/components";
import { Grid } from "semantic-ui-react";
import { graphql } from "gatsby";

import SecondaryLayout from "../components/SecondaryLayout.jsx";
import {
  getComponentFiles,
  getComponentContentNodeContent,
} from "../utils/utils";

export default class AboutPage extends React.PureComponent {
  render() {
    const { data } = this.props;
    const { fishermanBusiness, fishermanBusinessWebsitePage } = data;

    return (
      <SecondaryLayout>
        <SEO
          businessName={fishermanBusiness.name}
          pageName={fishermanBusinessWebsitePage.title}
          description={fishermanBusinessWebsitePage.description}
          images={getComponentFiles({
            components: fishermanBusinessWebsitePage.components,
            componentName: "HeroElement",
            numToSelect: 1,
          })}
          title={fishermanBusinessWebsitePage.seoTitle}
        />
        <PageLayout
          hero={
            <HeroElement
              header={getComponentContentNodeContent({
                components: fishermanBusinessWebsitePage.components,
                componentId: "content_section_header",
                defaultValue: "About",
              })}
              tagline={""}
              images={[
                "https://fisherman.gumlet.io/public/47372a28-2bba-411e-b98b-6a79e266eae9/large-George-Salon-About-Header.jpg?width=1250",
              ]}
              ctas={[]}
              ctaInverted={true}
              ctaColor={"white"}
              containerColor={"primary"}
              textColor={"white"}
              showMultiple={true}
              overlay={true}
              fullWidth={true}
              parallax={false}
              containerAs={"none"}
              height={500}
              carouselOptions={{ autoScrollTime: 10000 }}
            />
          }
          subfooter={false}
          className={"about-page"}
        >
          <Grid
            className={"about-content-container"}
            stackable={true}
            textAlign={"center"}
          >
            <Grid.Column
              width={8}
              textAlign={"left"}
              verticalAlign={"middle"}
              className={"markdown-column"}
            >
              <FadeReveal duration={1250} triggerOnce={true}>
                <MarkdownContent
                  content={getComponentContentNodeContent({
                    components: fishermanBusinessWebsitePage.components,
                    componentId: "content_section_description",
                  })}
                />
              </FadeReveal>
            </Grid.Column>
            <Grid.Column width={8} className={"image-column"}>
              <FadeReveal duration={1250} triggerOnce={true} delay={450}>
                <Image
                  src={getComponentFiles({
                    components: fishermanBusinessWebsitePage.components,
                    componentName: "Content.Image",
                    componentIdentifier: "content_section_image",
                    numToSelect: 1,
                  })}
                  background={false}
                  className={"image"}
                />
              </FadeReveal>
            </Grid.Column>
          </Grid>
          <Grid
            stackable={true}
            className={"component-section-container"}
            verticalAlign={"middle"}
            textAlign={"left"}
            centered={true}
          >
            <Grid.Column width={16} textAlign={"left"}>
              <div className={"about-section-custom-content"}>
                <p>
                  If you, or someone you know, has been a victim of or is
                  currently experiencing domestic violence; or if you question
                  whether your present relationship is unhealthy, please reach
                  out to these organizations for help.
                </p>
                <p>
                  The highly-trained advocates at Between Friends are available
                  24/7/365 to talk confidentially with anyone experiencing
                  domestic violence, seeking resources or information, or
                  questioning unhealthy aspects of their relationship.
                </p>
                <div>
                  <span>Call&nbsp;</span>
                  <Call
                    label={"1.800-603 HELP"}
                    phoneNumber={"8006034357"}
                    as={"text"}
                  />
                  <span>&nbsp;(4357) or visit&nbsp;</span>
                  <a
                    target={"_blank"}
                    href={"https://betweenfriendschicago.org/"}
                  >
                    www.betweenfriendschicago.org
                  </a>
                </div>
                <Image
                  size={"medium"}
                  centered={false}
                  src={
                    "https://fisherman.gumlet.io/public/47372a28-2bba-411e-b98b-6a79e266eae9/BetweenFriends.png"
                  }
                />
              </div>
            </Grid.Column>
          </Grid>
        </PageLayout>
      </SecondaryLayout>
    );
  }
}

export const query = graphql`
  query {
    fishermanBusiness {
      name
    }
    fishermanBusinessWebsitePage(pageType: { eq: "About" }) {
      title
      description
      components {
        fastId
        componentIdentifier
        order
        files {
          file
          altText
          gatsbyFile {
            childImageSharp {
              gatsbyImageData(layout: FULL_WIDTH)
            }
          }
        }
        contentNodes {
          content
        }
      }
      seoTitle
    }
  }
`;
